import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Home = lazy(() => import('../views/demo/Home'));
const Session = lazy(() => import('../views/demo/Session'));
const Nudge = lazy(() => import('../views/demo/Nudge'));
const NoPage = lazy(() => import('../views/404'));
const ManagerNudge = lazy(() => import('../views/demo/ManagerNudge'));
const BehaviourSurvey = lazy(() => import('../views/demo/BehaviourSurvey'));

const DemoRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/nudge/:id" element={<Nudge />} />
      <Route path="/manager-nudge" element={<ManagerNudge />} />
      <Route path="/session/:id" element={<Session />} />
      <Route path="/behaviour" element={<BehaviourSurvey />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};

export default DemoRouter;
